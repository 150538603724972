import React, { Fragment, useRef } from 'react';
import AlertMessage from './AlertMessage';
import t from '../Translations'

const Captcha = ({lang, tabIndex, inputRef, captchaImage, captchaValue, setCaptchaValue, validateCaptcha, validatingCaptcha, captchaOk, captchaMessage, readOnly}) => {
return (
    <div style={containerStyle}>
    {captchaImage &&  (
        <Fragment>
            <div style={inputItemStyle}>
                <div style={{width: 200}} dangerouslySetInnerHTML={{__html: captchaImage}} />
                {!captchaOk && (
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <input value={captchaValue} 
                            id={inputRef}
                            placeholder={t(lang, "captchaInstructions")}
                            onChange={(e) => {setCaptchaValue(e.target.value)}} 
                            readOnly={validatingCaptcha || captchaOk || readOnly} style={textInputStyle}
                            tabIndex={tabIndex}
                            ariaLabel={t(lang, "captchaInstructions")}
                            />
                        {!validatingCaptcha && !captchaOk && (<button tabIndex={tabIndex+1} style={{height: "2.4rem", marginLeft:10}} onClick={() => validateCaptcha()} >{t(lang, "validateCaptcha")}</button>)}
                
                        {validatingCaptcha && (<span style={{paddingLeft: 15}}><img src="indicator_wheel1.gif" alt="loading"/></span>)}
                    </div>
                )}
                {captchaOk && (<span style={{paddingLeft: 15}}><img src="checked.png" style={{height: "1.5em"}} alt="OK"/></span>)}
                {captchaMessage && (<AlertMessage message={captchaMessage}/>)}
            </div>         
        </Fragment>
    )}
    </div>
)
}

const containerStyle = {
    backgroundColor: "#FFFFFF",
    width: "60%",
    display:"flex",
    flexDirection: "column",
    fontFamily: "SST W01 Roman, Helvetica Neue, Helvetica, Arial, sans-serif",
}

const inputItemStyle = {
    display:"flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    margin: "5px",
  }
  
const textInputStyle= {
    width: 380,
    height: 35,
    padding: "5px 10px",
    border: "1px solid #aaa",
    borderRadius: 4,
}

export default Captcha;