import React, { useRef } from 'react';
import axios from "axios";
import t from '../Translations'

const FileUpload = ({state, tabIndex, dispatch}) => {
    const {language, uploading, uploadingComplete} = state;
    const message = state.fileUpload.message;
    const fileName = state.fileUpload.fileName;

    const fileInputRef = useRef();

    return (
    <div style={inputItem}>
        {uploadingComplete ?(
            <span>{fileName} <img src="checked.png" style={{height: "1.5em"}} alt="OK"/></span>
        ):(
            <React.Fragment>
            <input accept={supportedFileTypes}
                style={textInputStyle}
                id={"file_upload"}
                type="file"
                tabIndex={tabIndex}
                ref={fileInputRef}
                readOnly={uploading}
                ariaLabel={t(language, "uploadFile")}
                onChange={(e) => e.target.files.length > 0 && uploadFile(e.target.files[0], state, dispatch)} />
            {uploading && (<span style={{paddingLeft: 15}}><img src="indicator_wheel1.gif" alt="loading"/></span>)}
            {message && (
                <div style={messageStyle}>{message}</div>
            )}
            </React.Fragment>
        )}
    </div>

    )
};

const supportedFileTypes=",.ppt,.pptx,.doc,.docx,.xls,.xlsx,.xlsb,.pdf,.txt,.png,.jpg";


const uploadFile = (file, state, dispatch) => {
    const { language } = state;
    const lang = language;

    if (file.size>10*1024*1024) {
        dispatch({
            type: "fileUpload.message", 
            value: t(lang, "fileUploadSizeError") });
        return;
    }
    if (file.name.length<4) {
        dispatch({
            type: "fileUpload.message", 
            value: t(lang, "fileUploadTypeError")});
        return;
    }
    let point=file.name.lastIndexOf(".");
    if (point<=0) {
        dispatch({type: "fileUpload.message", value: t(lang, "fileUploadTypeError")});
        return;
    }
    const extension=file.name.substring(point).toLowerCase();
    if (supportedFileTypes.indexOf(extension)<0) {
        dispatch({type: "fileUpload.message", value: t(lang, "fileUploadTypeError")});
        return;
    }
    dispatch({type: "fileUpload.fileName", value: file.name});
    dispatch({type: "fileUpload.fileNameExtension", value: extension});
    dispatch({type: "fileUpload.uploading", value: true});
    dispatch({type: "fileUpload.message", value: null});
    const registrationId = state.registrationId;
    const captchaValue=state.captcha.value;
    // step 1: create signed url
    const id=encodeURIComponent(registrationId);
    const val=encodeURIComponent(captchaValue);
    const ext=encodeURIComponent(extension);
    const ct=encodeURIComponent(file.type);
    const fn=encodeURIComponent(file.name);
    axios.get(`${process.env.REACT_APP_API_URL}/get_upload_url?requestId=${id}&captchaValue=${val}&extension=${ext}&contentType=${ct}&fileName=${fn}`)
    .then(resp => {
        if (resp.data.message) {
            dispatch({type: "fileUpload.message", value: resp.data.message});
            return;
        }
        const uploadUrl=resp.data.uploadUrl;
        if (uploadUrl) {
            const options = {
                headers: {
                    "Content-Type": file.type,
                }
            }
            axios.put(uploadUrl, file, options).then(resp => {
                dispatch({type: "fileUpload.uploading", value: false});
                dispatch({type: "fileUpload.message", value: null});
                dispatch({type: "fileUpload.uploadingComplete", value: true});
            }).catch(err => {
                console.log("Error during file upload",err);
                dispatch({type: "fileUpload.message", value: "En error occurred. "+err});
            });
        }
    }).catch(err => {
        console.log("Error during file upload",err);
        dispatch({type: "fileUpload.message", value: "En error occurred. "+err});
    });


}

const inputItem = {
    display:"flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
  }

  const textInputStyle= {
    width: 380,
    height: 18,
    padding: "5px 10px",
    border: "1px solid #aaa",
    borderRadius: 4,
}

const messageStyle = {
    color: "red",
    fontWeight: "bold"
}

export default FileUpload;