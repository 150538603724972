import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './App.css';
import Lot5RegistrationScreen from './Log5RegistrationScreen';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import SonyHeader from './components/SonyHeader';
import SonyFooter from './components/SonyFooter';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
          <Route exact path="/" children={<Lot5RegistrationScreen />} />
          <Route path="/:locale" children={<Lot5RegistrationScreen />} />
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
