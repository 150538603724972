import React, { useMemo, useRef } from 'react';
import Captcha from './Captcha';
import ModelSelection from './ModelSelection';
import FileUpload from './FileUpload';
import AlertMessage from './AlertMessage';
import t from '../Translations'

const Lot5RegistrationForm = ({state, dispatch, validateCaptcha, submit, readOnly}) => {
    const {request, language, validationMessage} = state;
    const lang = language;
    const captchaInputRef=useRef();
    return (
<div style={containerStyle}>

    <div style={inputItemStyle}>
        <div style={labelStyle}>{t(lang, "modelName")} *</div>
        <ModelSelection 
            lang={state.language}
            modelName={request.model}
            onChange={value => dispatch({type: "request.model", value})}  
            alertMessage={validationMessage.model}
            readOnly={readOnly}
            />
    </div>
    <TextInput label={t(lang, "firstName") + " *"} value={request.firstName} alertMessage={validationMessage.firstName} onChange={value => dispatch({type: "request.firstName", value})} readOnly={readOnly} tabIndex="2"/>
    <TextInput label={t(lang, "lastName") + " *"} value={request.name} alertMessage={validationMessage.name} onChange={value => dispatch({type: "request.name", value})} readOnly={readOnly} tabIndex="3"/>
    <TextInput label={t(lang, "email") + " *"} value={request.email} alertMessage={validationMessage.email} onChange={value => dispatch({type: "request.email", value})} readOnly={readOnly} tabIndex="4"/>
    <TextInput label={t(lang, "companyName") + " *"} value={request.companyName} alertMessage={validationMessage.companyName} onChange={value => dispatch({type: "request.companyName", value})} readOnly={readOnly} tabIndex="5"/>
    <TextInput label={t(lang, "vat") + " *"} info={t(lang, "vatInfo")} value={request.vat} alertMessage={validationMessage.vat} onChange={value => dispatch({type: "request.vat", value})} readOnly={readOnly} tabIndex="6"/>
    
    <Captcha 
        lang={state.language}
        captchaImage={state.captcha.image} 
        captchaValue={state.captcha.value} 
        setCaptchaValue={value => dispatch({type: "captcha.value", value})}
        validateCaptcha={validateCaptcha}
        validatingCaptcha={state.captcha.validating}
        captchaOk={state.captcha.validationOk}
        captchaMessage={state.captcha.message}
        readOnly={readOnly}
        tabIndex={7}
        inputRef={captchaInputRef}
        />
    {state.captcha.validationOk && (
        <div style={inputItemStyle}>
            <div style={infoStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t(lang, "uploadInfo")}</div>
            <div style={inputItemStyle}>
                {!state.fileUpload.uploadingComplete && (
                    <div style={labelStyle}>{t(lang, "uploadFile") + " *"}</div>
                )}
                <FileUpload state={state} dispatch={dispatch} tabIndex={9}/>
            </div>
            {state.fileUpload.uploadingComplete && (
            <div style={inputItemStyle}>
                <br />
                <button onClick={() => {submit()}} disabled={readOnly} tabIndex={11}>{t(lang, "submitToSony")}</button>
            </div>
            )}
        </div>
    )}
    
</div>
        
    )
}

const TextInput = ({label, info, value, placeholder, alertMessage, tabIndex, readOnly, onChange}) => {
    return useMemo(() => {
    //console.log(`Redrawing ${label}`)
    return (
    <div style={inputItemStyle}>
        <div style={labelStyle}>{label}</div>
        {info && (
            <div style={infoStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{info}</div>
        )}
        <input 
            value={value} 
            onChange={(e) => {onChange(e.target.value)}} 
            style={alertMessage?textInputAlertStyle:textInputStyle} 
            placeholder={placeholder} 
            readOnly={readOnly}
            tabIndex={tabIndex}
            ariaLabel={label}
            />
        {alertMessage && (<AlertMessage message={alertMessage} />)}
    </div>
    )}, [label, info, value, placeholder, alertMessage, readOnly, onChange]);
}


const containerStyle = {
    backgroundColor: "#FFFFFF",
    width: "60%",
    display:"flex",
    flexDirection: "column",
    fontFamily: "SST W01 Roman|Helvetica Neue|Helvetica|Arial|sans-serif",
}

const inputItemStyle = {
  display:"flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  margin: "5px",
  width: "70%"
  
}

const labelStyle = {
    width: 380,
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "SST W01 Roman|Helvetica Neue|Helvetica|Arial|sans-serif",
}

const textInputStyle= {
    width: 380,
    height: 35,
    padding: "5px 10px",
    border: "1px solid #aaa",
    borderRadius: 4,
}

const textInputAlertStyle= {
    width: 380,
    height: 35,
    padding: "5px 10px",
    border: "2px solid #FF0000",
    borderRadius: 4,
}

const infoStyle = {
    backgroundColor: "#FDF6E5",
    color: "#2f353d",
    width: "100%",
    padding: "8px",
    fontWeigt: 400,
    lineHeight: 1.65,
    background: "#fdf6e5 url(warning.png) no-repeat 8px 11px",
}

export default Lot5RegistrationForm;